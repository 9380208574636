.footer {
  background: var(--color-blue);
  color: var(--white);
  font-weight: bold;
  padding-block: var(--gap-l);
}

.logo--footer {
  margin-bottom: var(--margin);

  @media (--media-md) {
    margin-bottom: var(--margin-l);
  }
}

.footer__navigation {
  > * {
    margin-bottom: var(--margin);

    @media (--media-md) {
      padding-left: var(--gap);
    }
  }
}

.footer__meta-nav {
  font-size: var(--fs-base);
  padding-top: 0.5em;
}

.footer__nav-link {
  display: block;
  min-width: max-content;

  &.is-main {
    font-size: var(--fs-call);
  }

  &:hover {
    text-decoration: underline;
  }

  &.is-newsletter {
    margin-top: var(--gap-s);
  }
}

.footer__top {
  text-align: center;
  display: block;
  padding: 0 2rem;

  :is(svg) {
    display: block;
    font-size: 2rem;
    margin: 0 auto;
  }
}

.footer__badges {
  margin-top: var(--gap);
  display: flex;
  justify-content: center;
}

.footer__badge {
  flex: 0 1 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.cys {
    :is(img) {
      height: 66%;
      margin-left: var(--gap-l);
    }
  }

  &.google {
    :is(img) {
      height: 55%;
    }
  }
}

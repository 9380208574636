.page-header {
  padding-block: var(--margin);
}

.page-header__title {
  display: inline-block;
  font-size: var(--fs-h3);
  font-weight: 400;
  font-style: italic;
  background-color: var(--color-services--strong);
  color: var(--black);
}

.project__lead-image {
  position: relative;
  aspect-ratio: 2000 / 1050;
  max-height: 45rem;
  width: 100%;
  overflow: hidden;
  margin-bottom: var(--margin);

  :is(img) {
    width: 100%;
  }

  :is(.copyright) {
    position: absolute;
    right: 2rem;
    bottom: 2px;
    display: block;
  }
}

.project__title {
  margin-bottom: var(--margin);
}

.project__info {
  gap: var(--gap) var(--gap-l);
}

.project__service {
  line-height: 1.125;
  margin-bottom: 0.35em;
}

.pill--project {
  font-size: var(--fs-xsmall);
}

.project__summary {
  text-transform: uppercase;
}

.project__details {
  padding-top: var(--gap-s);
}

.project-detail-container {
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    align-self: flex-start;
  }

  html[data-theme="dark"] & a {
    color: #8ca8de;
  }
}

.project__meta-title {
  font-size: var(--fs-small);
  text-transform: uppercase;
  margin-bottom: var(--gap-s);
}

.consultant {
  color: var(--black);
  margin-top: var(--margin);

  h3 {
    margin-bottom: 0;
  }
}

.consultant__content {
  background-color: var(--color-mint);
  padding: var(--gap);
  display: flex;
  flex-flow: column;
  gap: var(--gap-s);

  max-width: 28rem;
}

.consultant__contact {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem 1rem;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  line-height: 1;
  text-decoration: none;
  padding: 10px 20px;
  background: var(--fg);
  color: var(--bg);
  border: 1px solid transparent;
  cursor: pointer;
  font-size: var(--fs-small);
  font-weight: bold;
  transition: background-color var(--general-transition-speed);
  word-break: break-word;

  &:hover {
    background: var(--bg);
    color: var(--fg);
  }
}

.button--invert {
  background: var(--bg);
  color: var(--fg);

  &:hover {
    background: var(--fg);
    color: var(--bg);
  }
}

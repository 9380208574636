.services__service {
  letter-spacing: -0.05em;
  overflow-wrap: break-word;
}

.services__service h2 {
  font-weight: normal;
}

.services__service:hover,
.services__service:focus-visible {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#angebot {
  &:target {
    scroll-margin-top: 1000px;
    /*     animation: temporaryOutline 1.5s forwards 3;
 */
    .services-nav__service span {
      animation: temporaryFocus 1.5s forwards 3;
    }
  }
}

@keyframes temporaryFocus {
  0% {
    background-color: var(--color-services);
  }
  20% {
    background-color: var(--color-services--strong);
  }

  100% {
    background-color: var(--color-services);
  }
}

@keyframes temporaryOutline {
  0% {
    outline: transparent 2px solid;
  }
  20% {
    outline: var(--color-blue) 2px solid;
  }

  100% {
    outline: transparent 2px solid;
  }
}

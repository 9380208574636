:root {
  --black: #000000;
  --dark: #1a1a1a;
  --white: #ffffff;

  --color-blue: #3a66d9;
  --color-blue--royal: #0402aa;
  --color-yellow: #fbff00;
  --color-yellow--straw: #d9d85d;
  --color-mint: #83cebc;
  --color-lavender: #dedbfc;
  --color-lavender--purple: #a089e9;
  --color-services: hsla(61, 100%, 50%, 0.3);
  --color-services--strong: hsla(61, 100%, 50%, 1);

  --filters: var(--fg);

  --error-fg: #ff0000;
  --error-bg: #ffd7d7;
  --warning: orange;

  --fg: var(--black);
  --bg: var(--white);

  --container-max-width: 77.5rem;
  --prose-max-width: 56rem;
  /* clamping from 1440 to 640 calculated with: https://www.marcbacon.com/tools/clamp-calculator/ */
  /* paddings and gaps in pixel pleeeease*/
  --gap-l: clamp(30px, 14px + 2.5vw, 50px);
  --gap: clamp(20px, 12px + 1.25vw, 30px);
  --gap-s: clamp(12px, 6px + 1.25vw, 20px);
  --gap-xs: clamp(8px, 4px + 0.5vw, 12px);

  --margin-l: clamp(80px, 48px + 5vw, 120px);
  --margin: clamp(40px, 8px + 5vw, 80px);
  --margin-s: clamp(30px, 6px + 3.75vw, 60px);

  --border: 1px solid var(--fg);

  --z-skiplinks: 6;
  --z-top: 5;
  --z-flips: 4;
  --z-menu: 3;

  --nice: #473c67;

  --gradient-darkgray: #333;
  --gradient: var(--nice);

  /* Font sizes */

  --fs-xsmall: 0.875rem;
  --fs-small: 0.9375rem;
  --fs-base: 1.0625rem; /* 17px */

  --fs-h4: clamp(1.0625rem, 1.0125rem + 0.125vw, 1.125rem);
  --fs-h3: clamp(1.25rem, 1.05rem + 0.5vw, 1.5rem);
  --fs-h2: clamp(1.5rem, 1.1rem + 1vw, 2rem);

  --fs-call: clamp(2rem, 1.2rem + 2vw, 3rem); /* h1, h2 */
  --fs-section: clamp(2.5rem, -0.3rem + 7vw, 6rem); /* lead title*/

  --fs-main-nav: clamp(3rem, 0.6rem + 6vw, 6rem);
}

@media (--media-md) {
  :root {
    --fs-small: 1rem;
    --fs-base: 1.125rem; /* 18px */
  }
}

html[data-theme="dark"] {
  --gradient-darkgray: #333;
  --filters: #111;
  --fg: var(--white);
  --bg: var(--dark);
}

.darkgray {
  --gradient: var(--gradient-darkgray);
}

.intro__background {
  display: grid;
  place-items: center;
  min-height: min(60vh, 40vw);
}

.intro__logo {
  width: 50%;
}

.intro__feinheit {
  font-size: var(--fs-h2);

  :is(h2) {
    display: inline;
  }
}

.intro__text {
  max-width: var(--prose-max-width);
}

.intro__slider {
  overflow: hidden;
  position: relative;
  max-height: 45rem;
  width: 100%;
  aspect-ratio: 4 / 3;

  @media (--media-md) {
    aspect-ratio: 2000 / 1050;
  }
}

.intro__slide {
  position: absolute;
  inset: 0;

  display: block;
  background-size: cover;

  transition: 0.75s ease-in-out;
  opacity: 0;
  aspect-ratio: 4 / 3;
  background-position: center;

  @media (--media-md) {
    aspect-ratio: 2000 / 1050;
  }

  &.current {
    opacity: 1;
    z-index: 1; /* Clicks and tooltips */
  }
}

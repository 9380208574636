.newsletter {
  color: var(--black);
  background-color: var(--color-lavender);

  max-width: 37.5rem;
  padding: var(--gap);
  margin: 0 auto var(--margin-l);

  :where(p) {
    font-size: var(--fs-h3);
  }
}

.flips-wrapper {
  gap: var(--gap);
  align-items: start;
}

.flips {
  display: flex;
  flex-direction: column;
  padding: var(--gap);
  gap: var(--gap-s);
  color: var(--black);

  &:nth-child(3n + 1) {
    background-color: var(--color-mint);
  }

  &:nth-child(3n + 2) {
    background-color: var(--color-lavender);
  }

  &:nth-child(3n + 3) {
    background-color: var(--color-yellow);
  }

  :is(h1) {
    margin-bottom: 0;
  }
}

.flips__text {
  font-size: var(--fs-h3);
}

.icon--flips {
  margin-top: auto;
  margin-left: auto;
}

/* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* we have margin-top with .flow */
ul,
ol {
  margin-block: 0;
  padding-left: 1em;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:where([class]) {
  text-decoration-skip-ink: auto;
  color: inherit;
  text-decoration: none;
}

a:not([class]) {
  color: var(--color-blue);
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  html[data-theme="dark"] & {
    color: #8ca8de;
  }
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

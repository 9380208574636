@supports (grid-template-rows: masonry) {
  .articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: masonry;
  }

  .articles__article.cell {
    /* Reset the effect of the cell size classes */
    grid-column-end: span 1 !important;
  }
}

.articles {
  &:not(.masonry) {
    align-items: start;
  }

  .icon {
    --size: 1.75em;
  }
}

.articles__article {
  border: 3px solid var(--color-blue);

  background-color: var(--color-blue);
  color: var(--white);

  &:has(img) {
    background-color: var(--bg);
    color: var(--fg);
  }
}

.articles__image {
  position: relative;
  z-index: 1;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.articles__body {
  padding: var(--gap-s);
  overflow-wrap: break-word;
}

.articles__dateline {
  font-size: var(--fs-small);
}

.dateline {
  margin-bottom: var(--gap-xs);
}

.articles__title {
  font-size: var(--fs-h2);
  margin-top: var(--gap-xs);
}

.article__title {
  margin-bottom: var(--margin-s);
}

.articles__excerpt {
  @media (--media-sm-only) {
    /* XXX: To discuss */
    display: none;
  }
}

.articles__interesting {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: var(--gap);
}

@media (--media-sm-only) {
  .flips--article {
    height: 22rem;

    .flips__part--text {
      left: 1rem !important;
    }
  }
}

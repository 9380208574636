.projects {
  gap: var(--gap-l) var(--gap);
}

.projects__category {
  margin-block: var(--gap-xs);
  font-size: var(--fs-small);
}

html[data-theme="dark"] {
  --gradient: #8ca8de;
}

.filter .button,
.pill {
  background: transparent;
  color: var(--fg);
  border: var(--border);
  padding: 8px 12px;
  border-radius: 100rem;
}

.filter[data-show="_"] .button,
.filter .filter__active,
.button.pill {
  background: var(--fg);
  color: var(--bg);
  border-color: transparent;
}

.pills {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
}

.pill:not(a) {
  cursor: unset;
}

.prose {
  max-width: var(--prose-max-width);

  :is(h1, h2, h3, h4, h5, h6, p, ul, ol, dl) {
    max-width: var(--prose-max-width);
  }

  h1,
  h2 {
    overflow-wrap: break-word;
  }

  h3,
  h4 {
    + * {
      margin-top: 0;
    }
  }
}

.prose :where(a:not([class])) {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  html[data-theme="dark"] & {
    color: #8ca8de;
  }
}

.figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & img {
    margin: 0 auto;
    flex: 1 1 100%;
  }
}

.figcaption {
  margin-top: 1em;
}

.copyright {
  font-size: 10px;
  opacity: 0.7;
}

.video {
  max-width: 100%;
}

.lead {
  font-size: var(--fs-h3);
}

.small-text {
  font-size: var(--fs-small);
}

.angles.large-prose {
  display: flex;
  align-items: center;
}

.team__title {
  margin-bottom: var(--margin-s);
}

.team__feinheit {
  margin-bottom: var(--margin);
}

@media (--media-md) {
  @for $i from 1 to 2 {
    .team__member:nth-child($i) {
      order: -2;
    }
  }
}

@media (--media-lg) {
  @for $i from 1 to 2 {
    .team__member:nth-child($i) {
      order: -4;
    }
  }

  @for $i from 3 to 6 {
    .team__member:nth-child($i) {
      order: -2;
    }
  }
}

@media (--media-xl) {
  @for $i from 1 to 4 {
    .team__member:nth-child($i) {
      order: -4;
    }
  }

  @for $i from 5 to 10 {
    .team__member:nth-child($i) {
      order: -2;
    }
  }
}

.team__member {
  --contact-width: 2.5rem;
  display: flex;
  flex-direction: column;
}

.team__image-wrapper {
  aspect-ratio: 800 / 800;
  position: relative;
  perspective: 1500;

  transition: 0.5s;
  transform-style: preserve-3d;
}

body:not(.is-touch) .team__member:has(.team__image--back):hover .team__image-wrapper,
.is-touch .team__image-wrapper.flipped {
  transform: rotateY(180deg);
}

.is-touch .team__member:has(.team__image--back) .team__image--front::after {
  content: "";
  position: absolute;
  inset: 0;
  background: url("./static/assets/show-back.png") no-repeat;
  background-size: cover;
  backface-visibility: hidden;
}

.team__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: var(--image);
  background-repeat: no-repeat;
  backface-visibility: hidden;
  z-index: 2;
}

.team__image--back {
  transform: rotateY(180deg);
  z-index: 1;
}

.team__image--none {
  background: var(--gradient);
}

#after-dark {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

#after-dark:checked + .team .team__image {
  background-image: var(--after-dark, var(--image));
}

.team {
  gap: var(--margin) var(--gap);
}

.team__info {
  * {
    margin-block: var(--gap-xs);
  }
}

.team__contact {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  margin-top: auto;
  font-size: var(--fs-small);

  transition: left 0.3s ease-out;

  .team__member:hover &,
  .team__member:focus-visible & {
    left: 0;
  }

  :is(a) {
    text-decoration: none;
  }

  :is(.icon) {
    --size: 1.5em;
  }
}

@media (--media-sm-only) {
  .team__contact {
    left: 0;
  }
}

:root {
  --grid-columns: 12;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
  gap: var(--gap);
}

.cell {
  --width-sm: var(--grid-columns); /* Default width */
  --width: var(--width-sm);
  grid-column-end: span var(--width);
}

@media (--media-md) {
  .cell {
    --width-md: var(--width-sm);
    --width: var(--width-md);
  }
}

@media (--media-lg) {
  .cell {
    --width-lg: var(--width-md);
    --width: var(--width-lg);
  }
}

@media (--media-xl) {
  .cell {
    --width-xl: var(--width-lg);
    --width: var(--width-xl);
  }
}

@for $i from 1 to 12 {
  .cell.sm-$i {
    --width-sm: $i;
  }
  .cell.md-$i {
    --width-md: $i;
  }
  .cell.lg-$i {
    --width-lg: $i;
  }
  .cell.xl-$i {
    --width-xl: $i;
  }
}

.directive--title-text {
  margin-block: var(--gap-l);

  :is(h1, h2) {
    font-size: var(--fs-h3); /* not pretty, but we don't have to change all the content */
  }

  @media (--media-lg) {
    .cell:nth-of-type(1) {
      .prose {
        margin-right: unset;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: right;
      }
    }
  }

  .cell:nth-of-type(2) {
    .prose {
      margin-left: unset;
    }
    /* Like .lead */
    p {
      font-size: var(--fs-h3);
    }
  }
}
